@layer utilities {
  .imageContainer > div {
    @apply h-full;
  }

  .imageContainer > div > div {
    @apply h-full;
  }

  .pdpMobileSlider {
    min-height: 400px;
  }
}
