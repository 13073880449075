.yotpo-reviews-star-ratings-widget {
  @apply !mt-1;
}

.yotpo-score-option > span {
  @apply !font-sans;
}

#yotpo-reviews-main-widget .yotpo-review-title {
  @apply !ml-0;
  @apply !mt-2;
  @apply !mb-0;
}

#yotpo-reviews-main-widget .yotpo-review-rating-title {
  @apply !mt-4;
}

.yotpo-sr-bottom-line-summary {
  flex-direction: initial !important;
  column-gap: 0 !important;
}

.yotpo-review:not(.yotpo-media-modal-review)
  .yotpo-review-center-panel
  .yotpo-review-rating-title
  .yotpo-review-star-rating {
  @apply !mt-0;
}

.yotpo-sr-bottom-line-text.yotpo-sr-bottom-line-text--right-panel {
  @apply !pr-0;
  @apply !leading-none;
  @apply !mr-0;
}

.yotpo-sr-bottom-line-left-panel.yotpo-sr-bottom-line-score {
  @apply !leading-none;
  @apply !mr-0;
}

.yotpo-star-rating-icon {
  @apply !w-3 !h-3;
}

.yotpo-star-rating-reviews-summary-link {
  @apply !no-underline;
  @apply !gap-[2px];
}

.yotpo-star-rating-reviews-summary-link:hover {
  @apply !underline;
  @apply !text-blue-600;
}

.yotpo-star-rating-reviews-summary-link
  .yotpo-star-rating-reviews-summary-text {
  @apply !text-xs;
  @apply !text-blue-600;
}

.yotpo-star-rating-reviews-summary-link
  .yotpo-star-rating-reviews-summary-icon {
  @apply !items-center;
}

.yotpo-reviews-star-ratings-widget .yotpo-bottom-line-scroll-panel {
  @apply !items-center;
}

.yotpo-star-rating-reviews-summary-icon svg {
  height: 12px !important;
}

.yotpo-review-title::first-letter {
  @apply uppercase;
}

.yotpo-read-more-text,
.yotpo-read-more {
  @apply !text-sm;
}

.yotpo-read-more {
  @apply !block;
  @apply !ml-0;
  @apply !mt-2;
  @apply !underline;
  @apply !underline-offset-2;
  @apply !font-semibold;
}

.yotpo-review-thumbnail-container {
  @apply !min-h-fit;
  @apply !pb-0;
  @apply !overflow-x-auto;

  scrollbar-color: #dedede transparent;
  scrollbar-width: thin;
}

.yotpo-thumbnail.yotpo-image-thumbnail,
.yotpo-thumbnail.yotpo-video-thumbnail {
  @apply border;
  @apply border-nzxt-dark-grey-300;
  @apply rounded-md;
}

.modal-backdrop.yotpo-review-form-modal {
  @apply !z-50;
}

/* Yotpo QA  */

.yotpo-main-layout {
  @apply !w-full;
}

.yotpo-header-summary {
  @apply !text-left;
}

.yotpo-shopper-name {
  @apply !text-xs;
  @apply !font-bold;
}

.yotpo-date-format {
  @apply !text-xs;
}

.yotpo-question-center-panel .yotpo-question-content .yotpo-read-more-text {
  @apply !text-sm;
  @apply !font-bold;
}

.yotpo-question {
  @apply !px-4;
}

.yotpo-border-dotted {
  @apply !bg-transparent;
  @apply !border-t;
  @apply !h-auto;

  border-color: #9f9fb0 !important;
}

.yotpo-pagination-container {
  @apply !mb-0;
}

.yotpo-horizontal-pagination .yotpo-pagination-item {
  @apply !h-auto;
}

div#yotpo-reviews-main-widget,
div#yotpo-questions-and-answers {
  --primary-font-family: Gotham SSm, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  --secondary-font-family: Gotham SSm, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  --primary-color: rgba(107, 109, 118, 1) !important;
}

.dark div#yotpo-reviews-main-widget,
.dark div#yotpo-questions-and-answers {
  --primary-color: #fff !important;
  --text-color: #dddce7 !important;
  --background-color: transparent !important;
  --empty-state-button-color: #36373d !important;
  --product-custom-questions-color: #fff !important;
  --yotpo-pure-black: #fff !important;
  --yotpo-black: #fff !important;
  --yotpo-b2b-dark-blue: #fff !important;
  --yotpo-b2b-dark-grey: #fff !important;
  --yotpo-b2b-medium-grey: #c1c1 !important;
  --yotpo-b2b-light-grey: #9f9fb0 !important;
  --yotpo-initials-letters-grey: #717d9b !important;
  --yotpo-initials-letters-background-grey: #3e4456 !important;
  --yotpo-primary-text-black: #e0e0e0 !important;
  --yotpo-disabled: #4d4d4d !important;
  --yotpo-empty-white: #1e1e21 !important;
  --yotpo-form-dark-grey: #5f6786 !important;
  --yotpo-form-border-black: #929292 !important;
  --yotpo-separator-line-grey: #3a3a3a !important;
  --yotpo-light-grey: #2a2a2a !important;
  --yotpo-card-accent: #9a9d9f !important;
}

div#yotpo-questions-and-answers {
  --primary-font-weight: 400 !important;
}

.dark .yotpo-review:not(.yotpo-media-modal-review) {
  background: #212325 !important;
}

.dark #yotpo-reviews-main-widget .yotpo-filters-container-inner::before {
  color: var(--text-color) !important;
}

.dark #yotpo-reviews-main-widget input.yotpo-filter-select,
.dark #yotpo-reviews-main-widget input.yotpo-media-select {
  background-color: transparent !important;
  border: 1px solid #828292 !important;
}

#yotpo-reviews-main-widget button#yotpo-main-widget-btn,
.yotpo-question-btn.yotpo-ask-question-btn-s {
  @apply !h-11;
  @apply !rounded-md;
  @apply !border-none;
}

.dark #yotpo-reviews-main-widget button#yotpo-main-widget-btn,
.dark .yotpo-question-btn.yotpo-ask-question-btn-s,
.dark .yotpo-question-btn.yotpo-ask-question-btn-s span {
  @apply !text-white;

  background-color: #494851 !important;
}

.dark #yotpo-reviews-main-widget span.yotpo-reviewer-name {
  color: var(--text-color) !important;
}

.dark .yotpo-horizontal-pagination .selected {
  background: #212325 !important;
}

.dark .yotpo-scrolling-shadow {
  background: #212325 !important;

  @apply !bg-none;
}

.dark .yotpo-summary-title {
  @apply !mb-2;
}

.dark
  .yotpo-dropdown-content-wrapper
  .yotpo-dropdown-option[style*='background-color'] {
  @apply !bg-transparent;
}

.dark .yotpo-dropdown-content-wrapper {
  background-color: #212325 !important;
  border-color: #828292 !important;
}

.dark .yotpo-dropdown-base .yotpo-dropdown-arrow-icon path,
.dark .yotpo-media-filter circle,
.dark .yotpo-reviews-widget-dropdown path {
  stroke: #fff !important;
}

.dark .yotpo-star-rating-icon.yotpo-sr-star-empty path {
  fill: #36373d !important;
  stroke: none !important;
}

.dark .yotpo-star-rating-icon.yotpo-sr-star-half stop:last-child,
.dark .star-container.yotpo-sr-star-half stop:last-child {
  stop-color: #36373d !important;
}

.dark .yotpo-horizontal-pagination .yotpo-pagination-number:hover {
  background: #212325 !important;
}

.dark .star-container .yotpo-star-rating-icon.yotpo-sr-star-full,
.dark .yotpo-star-rating-icon.yotpo-sr-star-empty path,
.dark .yotpo-star-rating-icon.yotpo-sr-star-half path,
.dark .star-container.yotpo-sr-star-half path {
  stroke: none !important;
}

.dark .yotpo-dropdown-placeholder {
  @apply !text-nzxt-dark-grey-200;
}
