[data-reach-popover] {
  @apply z-50;
}

@screen lg {
  .c {
    scroll-padding: calc(var(--navSpaceLg) + 56px + 64px + 4px) 0 0;
  }
}

#klarna-placement::part(osm-container) {
  @apply font-sans;
  @apply text-sm;
  @apply bg-transparent;
  @apply text-left;
  @apply p-0;
}

.dark #klarna-placement::part(osm-container) {
  @apply text-white;
}

.dark #klarna-placement::part(osm-cta) {
  @apply font-sans;
  @apply text-sm;
  @apply text-nzxt-blue-300;
}

.dark #klarna-placement::part(osm-logo) {
  @apply text-white;
}

.dark #klarna-placement::part(osm-legal) {
  @apply text-nzxt-dark-grey-300;
  @apply text-xs;
}

.bld-summary #klarna-placement::part(osm-message) {
  @apply text-left md:text-right;
}

.scrollbar::-webkit-scrollbar {
  height: 9px;
}

.scrollbar::-webkit-scrollbar-track {
  @apply bg-white;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-nzxt-light-grey-200 rounded-full;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-nzxt-light-grey-400 rounded-full;
}
